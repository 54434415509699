import { Button, Text } from '@plandok/core'
import { SMALL_BREAKPOINT } from 'constants/breakpoints'
import useMediaQuery from 'hooks/screen/use-media-query'
import React from 'react'

import * as SC from './styles'

export default function NotFoundPage() {
   const isMobile = useMediaQuery(`(max-width: ${SMALL_BREAKPOINT}px)`)

   return (
      <SC.Container>
         <SC.Wrapper
            theme={{ background: isMobile ? '/images/notFoundPage/mobile.svg' : '/images/notFoundPage/desktop.svg' }}>
            <div>
               <Text label="market.notFound.title" size="large" colorType="brown" mb="large" />
               <a href="https://plandok.com" target="_self" rel="noopener noreferrer">
                  <Button whiteBtn label="market.notFound.btn.goBack" upperCase={false} bold />
               </a>
            </div>
         </SC.Wrapper>
      </SC.Container>
   )
}
