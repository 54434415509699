import { Language } from '@plandok/i18n'
import ModalContainer from 'components/Modal/ModalContainer'
import React from 'react'
import { useRoutes } from 'react-router-dom'

import { RoutePath } from './constants/route'
import AppointmentPage from './pages/AppointmentPage'
import BookFlowPage from './pages/BookFlowPage'
import LocationPage from './pages/LocationPage'
import NotFoundPage from './pages/NotFoundPage'
import PartnerPage from './pages/PartnerPage'

export const AppRouter = () => {
   const element = useRoutes([
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.LOCATION_PAGE}`,
         element: <LocationPage />,
      })),
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.BOOK_FLOW_PAGE}`,
         element: <BookFlowPage />,
      })),
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.APPOINTMENT_PAGE}`,
         element: <AppointmentPage />,
      })),
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.PARTNERS_PAGE}`,
         element: <PartnerPage />,
      })),
      ...Object.values(Language).map(key => ({
         path: `/${key}${RoutePath.LOCATION_PAGE}`,
         element: <LocationPage />,
      })),
      {
         path: RoutePath.LOCATION_PAGE,
         element: <LocationPage />,
      },
      {
         path: RoutePath.BOOK_FLOW_PAGE,
         element: <BookFlowPage />,
      },
      {
         path: RoutePath.APPOINTMENT_PAGE,
         element: <AppointmentPage />,
      },
      {
         path: RoutePath.PARTNERS_PAGE,
         element: <PartnerPage />,
      },
      {
         path: '*',
         element: <NotFoundPage />,
      },
   ])

   return (
      <>
         {element}
         <ModalContainer />
      </>
   )
}
