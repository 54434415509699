export const getAppointmentChangeTimeByCode = (code: string) => {
   switch (code) {
      case 'UP_TO_48_HOURS':
         return '48 hours'
      case 'UP_TO_24_HOURS':
         return '24 hours'
      case 'UP_TO_12_HOURS':
         return '12 hours'
      case 'UP_TO_6_HOURS':
         return '6 hours'
      case 'UP_TO_5_HOURS':
         return '5 hours'
      case 'UP_TO_4_HOURS':
         return '4 hours'
      case 'UP_TO_3_HOURS':
         return '3 hours'
      case 'UP_TO_2_HOURS':
         return '2 hours'
      case 'UP_TO_1_HOUR':
         return '1 hour'
      case 'UP_TO_30_MINUTES':
         return '30 minutes'
      default:
         return ''
   }
}

export const style = {
   base: {
      'color': 'grey',
      'fontSize': '16px',
      'width': '100%',
      'lineHeight': '24px',
      'fontStyle': 'italic',
      '::placeholder': {
         color: '#B3BCD0',
      },
   },
   invalid: {
      color: '#f5222d',
   },
}
