import { Button, Form, Text } from '@plandok/core'
import SelectServiceRow from 'components/SelectServiceRow'
import { MarketLocationService } from 'ghql'
import { CategoryItem } from 'pages/BookFlowPage/types'
import React, { useEffect, useState } from 'react'

import * as SC from './styles'

type LocationServicesProps = {
   isMobile: boolean
   categories: CategoryItem[]
   bookNow: () => void
}

export const LocationServices = ({ isMobile, categories, bookNow }: LocationServicesProps) => {
   const [viewService, setViewService] = useState<string>(categories[0].title)
   const [topServices, setTopServices] = useState<MarketLocationService[]>([])

   useEffect(() => {
      if (isMobile) {
         const allServices: MarketLocationService[] = []

         categories.forEach(({ services }: { services: MarketLocationService[] }) => allServices.push(...services))

         setTopServices(allServices.slice(0, 5))
      }
   }, [isMobile, categories])

   const description = categories.find(({ title }: { title: string }) => title === viewService)?.description
   const services = categories.find(({ title }: { title: string }) => title === viewService)?.services

   return (
      <SC.ServicesContainer>
         <Text
            size={isMobile ? 'xlarge' : 'xxxlarge'}
            weight="semiBold"
            mb={isMobile ? 'small' : 'large'}
            label="market.location.services.title"
            colorType="brown"
         />

         {isMobile ? (
            <>
               {topServices.map((item: MarketLocationService) => (
                  <Form key={item.id}>
                     {() => (
                        <SelectServiceRow currentService={item} allServices={topServices} categories={categories} />
                     )}
                  </Form>
               ))}
               <SC.BtnViewMoreWrapper>
                  <Button whiteBtn upperCase={false} onClick={bookNow}>
                     <Text mb="none" size="small" weight="semiBold" label="market.btn.viewMore" colorType="darkBrown" />
                  </Button>
               </SC.BtnViewMoreWrapper>
            </>
         ) : (
            <SC.ServicesWrapper>
               <SC.ServicesSticky>
                  {categories.map((item: CategoryItem, index: number) => (
                     <SC.ButtonService
                        key={index}
                        theme={{ selected: item.title === viewService }}
                        onClick={() => setViewService(item.title)}>
                        <Text mb="none" size="base" className="align-center">
                           <div className="mr-sm">{item.title}</div>
                           {item.services.length}
                        </Text>
                     </SC.ButtonService>
                  ))}
               </SC.ServicesSticky>

               <SC.ServicesBlock>
                  {description && (
                     <Text mb="large" size="base" colorType="brown">
                        {description}
                     </Text>
                  )}

                  {services &&
                     services.map((item: MarketLocationService) => (
                        <Form key={item.id}>
                           {() => (
                              <SelectServiceRow currentService={item} allServices={services} categories={categories} />
                           )}
                        </Form>
                     ))}
               </SC.ServicesBlock>
            </SC.ServicesWrapper>
         )}
      </SC.ServicesContainer>
   )
}
