import { SelectLanguageModal } from '@plandok/core'
import { FunctionComponent } from 'react'

import CancelAppointmentModal from '../modals/CancelAppointmentModal'
import SuccessCancelAppointmentModal from '../modals/SuccessCancelAppointmentModal'

type ModalMap = {
   [key: string]: FunctionComponent
}

export const ModalMap: ModalMap = {
   LANGUAGE_SELECT: SelectLanguageModal,
   CANCEL_APPOINTMENT: CancelAppointmentModal,
   SUCCESS_CANCEL_APPOINTMENT: SuccessCancelAppointmentModal,
}
