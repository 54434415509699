import { CustomIcon, Text } from '@plandok/core'
import React from 'react'

import * as SC from './styles'

type AppointmentCancelledVariablesProps = {
   appointmentIsCancelled: boolean
   isSmall: boolean
   goToLocationPage: () => void
   email?: string | null
   phone?: string | null
}

export default function AppointmentCancelledVariables({
   appointmentIsCancelled,
   isSmall,
   goToLocationPage,
   email,
   phone,
}: AppointmentCancelledVariablesProps) {
   return appointmentIsCancelled ? (
      <>{isSmall && <SC.BtnClose label="btn.close" onClick={goToLocationPage} upperCase={false} bold block />}</>
   ) : (
      <>
         <Text label="market.confirmation.scheduledAppointment.title" colorType="black" mb="none" size="base" />
         <Text
            label="market.confirmation.scheduledAppointment.description"
            colorType="black"
            mb={isSmall ? 'base' : 'small'}
            size="base"
         />
         {email && (
            <SC.EmailContainer>
               <CustomIcon type="transparedEmail" />
               <a href={`mailto:${email}`}>{email}</a>
            </SC.EmailContainer>
         )}
         {phone && (
            <SC.PhoneContainer>
               <CustomIcon type="transparedPhone" />
               <a href={`tel:${phone}`}>{phone}</a>
            </SC.PhoneContainer>
         )}
      </>
   )
}
