import styled from 'styled-components'

export const MobileHeaderPicker = styled.div`
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 28px;

   button {
      padding: 0;

      svg {
         width: 20px;
         height: 20px;

         path {
            stroke: #2d2825;
         }
      }
   }
`
