import { phonePrefixOptions } from '@plandok/core'
import axios from 'axios'

export const getLocationInfo = async () => {
   try {
      const res: any = await axios.get(`https://ip-api.io/json?api_key=${process.env.REACT_APP_IP_API_KEY || ''}`)
      const data = res?.data

      return {
         callingCode: phonePrefixOptions.find(option => option.value === `+${data?.callingCode}`)?.value,
      }
   } catch (e) {
      console.log('%ce', 'color: green; font-size: 1.5rem;', e)
      return {}
   }
}
