import { Text } from '@plandok/core'
import { MOBILE_BREAKPOINT } from 'constants/breakpoints'
import { MarketPartner } from 'ghql'
import useMediaQuery from 'hooks/screen/use-media-query'
import React, { useEffect, useRef, useState } from 'react'

import * as SC from '../../styles'

export const BusinessInfo = ({ partner }: { partner: MarketPartner }) => {
   const [isReadMore, setIsReadMore] = useState(false)
   const [height, setHeight] = useState(0)

   const heightDescription = useRef<HTMLInputElement>(null)

   const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`)

   useEffect(() => {
      const getHeight = heightDescription?.current?.clientHeight

      if (getHeight && getHeight > 100) {
         setHeight(getHeight)
         setIsReadMore(true)
      }
   }, [])

   const toggleReadMore = () => setIsReadMore(!isReadMore)

   return (
      <SC.BusinessContainer>
         <Text
            size={isMobile ? 'large' : 'xxlarge'}
            mb={isMobile ? 'medium' : 'small'}
            weight="semiBold"
            colorType="black">
            {partner?.name ?? ''}
         </Text>

         <SC.DescriptionBusiness ref={heightDescription}>
            {isReadMore ? (
               <SC.LessText size="base" mb="none" colorType="black">
                  {partner?.description ?? ''}
               </SC.LessText>
            ) : (
               <Text size="base" mb="none" colorType="black">
                  {partner?.description ?? ''}
               </Text>
            )}
         </SC.DescriptionBusiness>
         {(isReadMore || height > 100) && (
            <SC.ButtonRead
               upperCase={false}
               whiteBtn
               label={isReadMore ? 'market.btn.readMore' : 'market.btn.showLess'}
               onClick={toggleReadMore}
            />
         )}
      </SC.BusinessContainer>
   )
}
