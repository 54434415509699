import { Form, useStripeHook } from '@plandok/core'
import { useStripe } from '@stripe/react-stripe-js'
import { message } from 'antd'
import { MOBILE_BREAKPOINT } from 'constants/breakpoints'
import useMediaQuery from 'hooks/screen/use-media-query'
import React from 'react'

import { BookingFlowHeader } from './components/BookFlowHeader'
import * as SC from './styles'
import { BookingFlowForm, BookingFlowStepType } from './types'

interface BookingFlowStepProps {
   step: number
   isLastStep: boolean
   stepConfig: BookingFlowStepType
   onBack: () => void
   onNext: (response: Object) => void
   onClose: () => void
   onSuccess(): any
   onSetStep: () => void
   initialValues?: any
   recaptchaRef?: any
   setIsBackClicked?: any
   onAppointmentValidate?: any
}

export default function StripeBookingFlowStep({
   stepConfig: { component: StepComponent, ...stepConfig },
   initialValues,
   onNext,
   onSuccess,
   isLastStep,
   onSetStep,
   step,
   onBack,
   onClose,
   setIsBackClicked,
   onAppointmentValidate,
}: BookingFlowStepProps) {
   const stripe = useStripe()
   const stripeService = useStripeHook()
   const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`)

   const onSubmitForm = async (form: BookingFlowForm) => {
      const isFormValid = form && !!Object.keys(form).length && form.constructor === Object
      setIsBackClicked(false)

      if (isFormValid && isLastStep) {
         let response = await onAppointmentValidate(form)
         const createAppointment = response?.data?.createAppointment

         let status = createAppointment?.paymentStatus
         let clientSecret = createAppointment?.clientSecret

         if (null === clientSecret) {
            onNext(response)
            return
         }

         let confirmation = await stripeService.confirmPayment(stripe, status, clientSecret)

         if (true === confirmation.response) {
            onNext(response)
         } else {
            message.error(confirmation?.errorMessage)
         }
      }
   }

   return (
      <Form initialValues={initialValues} onSubmit={onSubmitForm} onSuccess={onSuccess}>
         {({ values, submitting, formError }: any) => {
            const updatedValues = {
               ...values,
               isLastStep,
               submitting,
               onNext: onSubmitForm,
               onSetStep,
            }

            const formAlert = formError && formError !== 'validation'

            return (
               <>
                  <BookingFlowHeader
                     {...stepConfig}
                     step={step + 1}
                     onBack={onBack}
                     onClose={onClose}
                     isMobile={isMobile}
                  />
                  <SC.BodyContainer theme={{ step: step + 1, isMobile }}>
                     <StepComponent values={updatedValues} step={step + 1} />
                     {formAlert && <Form.Alert text={formError} style={{ marginBottom: '10px' }} />}
                  </SC.BodyContainer>
               </>
            )
         }}
      </Form>
   )
}
