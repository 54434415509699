import styled from 'styled-components'

type ComponentProps = {
   overlay?: boolean
}

export const Overlay = styled.div<ComponentProps>`
   ${({ overlay }: ComponentProps) => {
      if (overlay)
         return `
        @media screen and (max-width: 1024px) {
          position: relative;
  
          :after {
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: linear-gradient(
              180deg,
              rgba(53, 57, 69, 0) 0%,
              rgba(43, 47, 60, 0.398438) 30.21%,
              rgba(0, 0, 0, 0.9) 100%
            );
          }
        }
      `
   }}
`

export const ButtonsContainer = styled.div`
   display: flex;
   justify-content: center;
   margin-top: 8px;
   button {
      width: 40px;
      height: 40px;
   }
   button:first-of-type {
      margin-right: 3px;
   }
   button:last-of-type {
      margin-left: 3px;
      svg {
         transform: rotate(180deg);
      }
   }
`
