import { useQuery } from '@apollo/client'
import { PaymentType, removeEmojiFromString, useNavigation, useParams } from '@plandok/core'
import { IntlLabel, ReactIntl } from '@plandok/i18n'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { message } from 'antd'
import FlowStatusBlock from 'components/FlowStatusBlock'
import InfinitySpinner from 'components/InfinitySpinner'
import { MOBILE_BREAKPOINT } from 'constants/breakpoints'
import { MarketLocationResponse, mutate, mutation, query } from 'ghql'
import useMediaQuery from 'hooks/screen/use-media-query'
import pick from 'lodash/pick'
import { PaymentStep } from 'pages/BookFlowPage/components/steps/PaymentStep'
import StripeBookingFlowStep from 'pages/BookFlowPage/StripeBookingFlowStep'
import React, { useContext, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useStore } from 'store'

import BookingFlowStep from './BookingFlowStep'
import ServiceSelectStep from './components/steps/ServiceSelectStep'
import StaffAndDateSelectStep from './components/steps/StaffAndDateSelectStep'
import * as SC from './styles'
import { BookingFlowForm, BookingFlowStepType } from './types'

export default function BookFlowPage() {
   const [isBackClicked, setIsBackClicked] = useState(false)
   const [initialValues, setInitialValues] = useState<any>()
   const [step, setStep] = useState(0)

   const { lang } = useContext(ReactIntl)
   const { navigate, location } = useNavigation()

   const params: any = useParams()

   const setEmployeeId = useStore(state => state.setEmployeeId)

   const recaptchaRef = useRef<ReCAPTCHA>(null)

   const context = { language: lang }

   const slug = params?.slug

   const locationState: any = location.state
   const preselectedServiceId = locationState?.id

   const employeeId = useStore(state => state.employeeId)
   const paymentMethod = useStore(state => state.paymentMethod)
   const paymentType = useStore(state => state.paymentType)

   const paymentTypes = initialValues?.partner.paymentTypes
   const paymentSecret = initialValues?.partner.paymentSecret
   const paymentAccountId = initialValues?.partner.paymentAccountId
   const isAllowEmployeeSelection = initialValues?.partner.allowEmployeeSelection
   const navigateToLocationUrl = params?.locale ? `/${lang}/${slug}` : `/${slug}`
   const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`)

   const serviceIds = useStore(state => state.serviceIds)
   const setServiceIds = useStore(state => state.setServiceIds)

   const { data: locationData, loading: locationLoading } = useQuery<MarketLocationResponse>(query.MARKET_LOCATION, {
      variables: { context, slug },
   })

   const isLastStep = isMobile ? step === 3 : step === 2

   useEffect(() => {
      if (locationData && !initialValues) {
         const newLocationData = { ...locationData?.location, phone: '' } as any
         const phone = locationData?.location?.phone || null

         setInitialValues(
            preselectedServiceId
               ? { serviceIds: [preselectedServiceId], ...newLocationData, ownerPhone: phone }
               : { ...newLocationData, ownerPhone: phone },
         )
      }
      setEmployeeId(null)
   }, [locationData, locationState, preselectedServiceId, setEmployeeId, initialValues, setServiceIds])

   useEffect(() => {
      setServiceIds(initialValues?.serviceIds ?? [])
   }, [initialValues])

   const [stripePromise, setStripePromise] = useState<any | undefined>()

   useEffect(() => {
      if (paymentTypes?.includes(PaymentType.STRIPE_PAYMENT)) {
         setStripePromise(
            loadStripe(paymentSecret, {
               stripeAccount: paymentAccountId,
            }),
         )
      }
   }, [paymentSecret, paymentTypes, paymentAccountId])

   const steps: BookingFlowStepType[] = [
      {
         title: 'market.ob.services.step.title',
         component: ServiceSelectStep,
      },
      {
         title: isAllowEmployeeSelection ? 'market.ob.employee.step.title' : 'market.ob.timeSlot.step.title',
         component: StaffAndDateSelectStep,
      },
      {
         title: 'market.ob.personalInfo.step.title',
         component: PaymentStep,
      },
      {
         title: 'market.ob.confirm.step.title',
         component: FlowStatusBlock,
      },
   ]

   const stepConfig = steps[step]

   const onSuccess = () => {
      if (!isLastStep && !isBackClicked) {
         setStep(step + 1)
      }
   }

   const onSetStep = () => {
      if (!isBackClicked) {
         setStep(step + 1)
      }
   }

   const onNext = (response: any) => {
      const createAppointment = response?.data?.createAppointment

      const errors = response?.errors
      const validationError = response?.errors?.error

      const success = createAppointment?.success

      const appointmentId = createAppointment?.appointmentId ?? ''
      const entryToken = createAppointment?.entryToken ?? ''

      const navigateToAppointmentPage = params?.locale
         ? `/${lang}/appointment/${appointmentId}/${entryToken}`
         : `/appointment/${appointmentId}/${entryToken}`

      if (createAppointment && success && !errors) {
         message.success(<IntlLabel label="market.ob.success.message" />)
         navigate(navigateToAppointmentPage, {}, { state: { locale: params?.locale } })
      }

      if (validationError) {
         message.error(<IntlLabel label={validationError} />)
      }

      if (errors) {
         message.error(<IntlLabel label={errors?.['FINAL_FORM/form-error'] || errors[0].message} />)
      }
   }

   const onAppointmentValidate = async (form: BookingFlowForm) => {
      console.log(recaptchaRef?.current)
      const token = await recaptchaRef?.current?.executeAsync()
      console.log(token)
      let updatedForm = {
         ...form,
         locationId: initialValues.id,
         email: form.userEmail ? form.userEmail.trim() : null,
         phone: form.phone ? removeEmojiFromString(form.phone) : null,
         employeeId,
         serviceIds: serviceIds,
         paymentType: paymentType,
         paymentMethod: paymentMethod,
         recaptcha: token,
      }

      recaptchaRef?.current?.reset()

      return await mutate(
         mutation.CREATE_APPOINTMENT,
         true,
      )({
         context,
         input: pick(
            updatedForm,
            'firstName',
            'lastName',
            'email',
            'phone',
            'locationId',
            'employeeId',
            'datetime',
            'notes',
            'serviceIds',
            'paymentType',
            'paymentMethod',
            'recaptcha',
         ),
      })
   }

   const onClose = () => {
      navigate(navigateToLocationUrl)
      setServiceIds([])
   }

   const onBack = () => {
      if (!step) {
         return onClose()
      }
      setStep(step - 1)
      setIsBackClicked(true)
   }

   return (
      <>
         {locationLoading ? (
            <InfinitySpinner />
         ) : (
            <>
               <SC.PageContainer>
                  {stripePromise && paymentTypes?.includes(PaymentType.STRIPE_PAYMENT) ? (
                     <Elements stripe={stripePromise}>
                        <StripeBookingFlowStep
                           initialValues={initialValues || {}}
                           step={step}
                           stepConfig={stepConfig}
                           onBack={onBack}
                           onNext={onNext}
                           onSuccess={onSuccess}
                           onSetStep={onSetStep}
                           onClose={onClose}
                           isLastStep={isLastStep}
                           recaptchaRef={recaptchaRef}
                           setIsBackClicked={setIsBackClicked}
                           onAppointmentValidate={onAppointmentValidate}
                        />
                     </Elements>
                  ) : (
                     <BookingFlowStep
                        initialValues={initialValues || {}}
                        step={step}
                        stepConfig={stepConfig}
                        onBack={onBack}
                        onNext={onNext}
                        onSuccess={onSuccess}
                        onSetStep={onSetStep}
                        onClose={onClose}
                        isLastStep={isLastStep}
                        recaptchaRef={recaptchaRef}
                        setIsBackClicked={setIsBackClicked}
                        onAppointmentValidate={onAppointmentValidate}
                     />
                  )}
                  {isLastStep && (
                     // @ts-ignore
                     <ReCAPTCHA
                        sitekey={'6LengtMZAAAAABu13Z5q1Cgt_uq_hTHyYSqKaZZi'}
                        size="invisible"
                        ref={recaptchaRef}
                     />
                  )}
               </SC.PageContainer>
            </>
         )}
      </>
   )
}
