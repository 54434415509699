import { CustomIcon, withModal } from '@plandok/core'
import React from 'react'

import * as SC from '../styles'

type SuccessCancelAppointmentModalProps = {
   onClose: () => void
}

function SuccessCancelAppointmentModal({ onClose }: SuccessCancelAppointmentModalProps) {
   return (
      <SC.SuccessCancelAppointmentContainer>
         <CustomIcon type="confirmedCancelAppointmentCalendar" />
         <SC.SansText
            label="market.modal.cancelledAppointment.title"
            colorType="brown"
            weight="medium"
            size="large"
            mb="large"
         />
         <SC.BtnClose label="btn.close" onClick={onClose} upperCase={false} bold block />
      </SC.SuccessCancelAppointmentContainer>
   )
}

export default withModal(null, { contentClassName: 'success-cancel-appointment-modal' })(SuccessCancelAppointmentModal)
