import { IconButton } from '@plandok/core'
import { Carousel } from 'antd'
import React, { useRef } from 'react'

import * as SC from './styles'

type LocationCarouselProps = {
   photos: string[]
   buttons: boolean
   overlay?: boolean
   dots?: boolean
}

export const LocationCarousel = ({ photos, buttons, overlay, dots = true }: LocationCarouselProps) => {
   const carouselRef: any = useRef(null)

   const handlePrev = () => carouselRef.current.prev()
   const handleNext = () => carouselRef.current.next()

   return (
      <>
         <Carousel ref={carouselRef} dots={dots}>
            {photos?.map((photo, index) => (
               <SC.Overlay key={index} overlay={overlay}>
                  <img src={photo} alt={`{image}-${index}`} />
               </SC.Overlay>
            ))}
         </Carousel>

         {buttons && (
            <SC.ButtonsContainer>
               <IconButton icon="arrowSlider" onClick={handlePrev} />
               <IconButton icon="arrowSlider" onClick={handleNext} />
            </SC.ButtonsContainer>
         )}
      </>
   )
}
