type ParamsType = {
   locale: string
   slug: string
}

export const getMetaTagURL = (params: ParamsType, slug: string): string => {
   return params.locale
      ? `${process.env.REACT_APP_URL}/${params.locale}/${slug}`
      : `${process.env.REACT_APP_URL}/${slug}`
}
