import { Button } from '@plandok/core'
import styled from 'styled-components'

export const BtnClose = styled(Button)`
   color: #353945 !important;
   background: #e6e8ec;
   max-width: 173px;
   min-height: 48px;
   margin: 0 auto;
`

export const EmailContainer = styled.div`
   display: flex;
   align-items: center;
   margin: 0 auto 12px;
   a {
      text-decoration: none;
      margin-left: 11px;
      color: #225ffe;
   }
`

export const PhoneContainer = styled.div`
   display: flex;
   align-items: center;
   margin: 0 auto;
   a {
      text-decoration: none;
      margin-left: 5px;
      color: #000000;
   }
`
