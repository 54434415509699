import { Card } from '@plandok/core'
import styled from 'styled-components'

export const CardContainer = styled.div`
   filter: drop-shadow(0px 5px 10px rgba(126, 154, 182, 0.25));
   width: 295px;
   margin: ${({ theme }) => theme.fourthStep && '80px auto 30px'};
`

export const CardWrapper = styled.div`
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
`

export const CustomCard = styled(Card)`
   width: 295px;
   padding: 0;
   clip-path: polygon(
      100% 0%,
      0% 0%,
      0% 97.82%,
      1% 97.91%,
      2% 98.15%,
      3% 98.51%,
      4% 98.92%,
      5% 99.31%,
      6% 99.6%,
      7% 99.75%,
      8% 99.72%,
      9% 99.53%,
      10% 99.2%,
      11% 98.8%,
      12% 98.4%,
      13% 98.07%,
      14% 97.86%,
      15% 97.83%,
      16% 97.97%,
      17% 98.25%,
      18% 98.63%,
      19% 99.04%,
      20% 99.41%,
      21% 99.66%,
      22% 99.76%,
      23% 99.68%,
      24% 99.44%,
      25% 99.09%,
      26% 98.68%,
      27% 98.29%,
      28% 97.99%,
      29% 97.84%,
      30% 97.85%,
      31% 98.04%,
      32% 98.36%,
      33% 98.75%,
      34% 99.16%,
      35% 99.5%,
      36% 99.71%,
      37% 99.75%,
      38% 99.63%,
      39% 99.35%,
      40% 98.97%,
      41% 98.56%,
      42% 98.19%,
      43% 97.93%,
      44% 97.82%,
      45% 97.89%,
      46% 98.12%,
      47% 98.47%,
      48% 98.87%,
      49% 99.27%,
      50% 99.57%,
      51% 99.74%,
      52% 99.73%,
      53% 99.56%,
      54% 99.25%,
      55% 98.85%,
      56% 98.44%,
      57% 98.1%,
      58% 97.88%,
      59% 97.82%,
      60% 97.94%,
      61% 98.21%,
      62% 98.58%,
      63% 98.99%,
      64% 99.37%,
      65% 99.64%,
      66% 99.76%,
      67% 99.7%,
      68% 99.48%,
      69% 99.13%,
      70% 98.73%,
      71% 98.33%,
      72% 98.02%,
      73% 97.85%,
      74% 97.84%,
      75% 98.01%,
      76% 98.31%,
      77% 98.7%,
      78% 99.11%,
      79% 99.46%,
      80% 99.69%,
      81% 99.76%,
      82% 99.65%,
      83% 99.39%,
      84% 99.02%,
      85% 98.61%,
      86% 98.23%,
      87% 97.95%,
      88% 97.83%,
      89% 97.87%,
      90% 98.08%,
      91% 98.42%,
      92% 98.83%,
      93% 99.22%,
      94% 99.54%,
      95% 99.73%,
      96% 99.74%,
      97% 99.59%,
      98% 99.29%,
      99% 98.9%,
      100% 98.49%
   );
`

export const ImageContainer = styled.div`
   background-image: url(${({ theme }) => theme.background});
   height: 128px;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   border-top-right-radius: 10px;
   border-top-left-radius: 10px;
   display: flex;
   align-items: flex-end;

   div {
      text-align: center;
      background: #353945;
      opacity: 0.92;
      width: 100%;
      line-height: 21px;
      padding: 5px;
   }
`

export const InfoContainer = styled.div`
   padding: 25px 24px 22px;
`

export const TimeContainer = styled.div`
   display: flex;
   align-items: center;
   margin-bottom: 13px;
`

export const AssignEmployeeContainer = styled.div`
   display: flex;
   align-items: center;
   margin-top: 13px;
`

export const Date = styled.h2`
   font-weight: 700;
   font-size: 20px;
   color: ${({ theme }) => theme.primaryColor};
`

export const NoDataContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   min-height: 100px;
   line-height: 24px;
   div {
      color: #777e90;
   }
`

export const ServiceContainer = styled.div`
   margin-bottom: 12px;
`

export const ButtonContainer = styled.div`
   text-align: center;

   button {
      min-width: 216px;
      background: #225ffe;
      font-weight: 500;
      border: none;
   }
`
