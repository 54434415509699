import './style.less'

import { Button, IconButton, Text, useLocation } from '@plandok/core'
import SelectServiceRow from 'components/SelectServiceRow'
import { MOBILE_BREAKPOINT } from 'constants/breakpoints'
import { MarketLocationService } from 'ghql'
import useMediaQuery from 'hooks/screen/use-media-query'
import useScrollingServices from 'hooks/use-scrolling-services'
import { CategoryItem } from 'pages/BookFlowPage/types'
import React, { useRef, useState } from 'react'

import { scrollHandler, scrollLeft, scrollRight, setActiveTitlePosition } from './helpersForScrolling'
import * as SC from './styles'

type BookFlowServicesProps = {
   categories: CategoryItem[]
   bookingLimit: number
}

const HEIGHT_TITLES_BLOCK = 65

export const BookFlowServices = ({ categories, bookingLimit }: BookFlowServicesProps) => {
   const { location } = useLocation()
   const [viewService, setViewService] = useState<string>(location.state?.activeTitle || categories[0].title)

   const titlesContainer = useRef<HTMLDivElement | null>(null)
   const leftArrow = useRef<HTMLDivElement | null>(null)
   const rightArrow = useRef<HTMLDivElement | null>(null)

   const description = categories.find(({ title }) => title === viewService)?.description
   const services = categories.find(({ title }) => title === viewService)?.services

   const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`)

   const activeServiceBlock = {
      id: location.state?.activeTitle || 0,
      position: 0,
   }

   useScrollingServices(leftArrow, scrollHandler, isMobile, location, HEIGHT_TITLES_BLOCK)

   // useScreenTouchServices(isMobile, 300)

   document?.querySelectorAll('.blocks > *').forEach((elem: Element) => {
      const headerBottom = document?.querySelector('.titles')

      if (headerBottom) {
         const position =
            elem?.getBoundingClientRect().top - headerBottom.getBoundingClientRect().bottom + HEIGHT_TITLES_BLOCK

         if (position < HEIGHT_TITLES_BLOCK) {
            activeServiceBlock.id = Number((elem as HTMLElement).dataset.id)
            activeServiceBlock.position = position
         }
      }
   })

   document?.querySelector(`.titles > * > .active`)?.classList.remove('active')
   document?.querySelector(`.titles > * > [data-id="${activeServiceBlock.id}"]`)?.classList.add('active')

   const timeoutId = window.setTimeout(() => setActiveTitlePosition(timeoutId), 50)

   // document?.querySelector('.titles > .titles-container')?.childNodes.forEach(child =>
   //    child.addEventListener('click', ,
   // )

   const handleScrollOnClick = React.useCallback((event: any) => {
      event.preventDefault()
      const id = event.currentTarget?.dataset.id

      if (id) {
         document?.querySelector(`.titles > * > .active`)?.classList.remove('active')
         document?.querySelector(`.titles > * > [data-id="${id}"]`)?.classList.add('active')

         const pagePosition = window.scrollY
         const activeTopPosition: number | undefined = document
            ?.querySelector(`.blocks [data-id="${id}"]`)
            ?.getBoundingClientRect().top
         const titlesElem: HTMLDivElement | null = document?.querySelector('.titles')
         const titlesBottomLinePosition: number | null = titlesElem ? titlesElem.clientHeight : null

         if (activeTopPosition && titlesBottomLinePosition) {
            window?.scrollTo({
               top: activeTopPosition + pagePosition - (titlesBottomLinePosition + HEIGHT_TITLES_BLOCK),
               behavior: 'smooth',
            })
         }
      }
   }, [])

   const setActiveService = (title: string) => setViewService(title)

   return (
      <>
         {isMobile ? (
            <>
               <div
                  className="scrolling-right"
                  onClick={event => scrollRight(event, titlesContainer, leftArrow, rightArrow)}
                  ref={leftArrow}>
                  <IconButton icon="arrowSlider" />
               </div>

               <div className="titles">
                  <div className="titles-container" ref={titlesContainer}>
                     {categories.map((item, idx) => (
                        <Button data-id={`${idx}`} key={idx} upperCase={false} onClick={handleScrollOnClick}>
                           {item.title} {item.services.length}
                        </Button>
                     ))}
                  </div>
               </div>

               <div
                  className="scrolling-left"
                  onClick={event => scrollLeft(event, titlesContainer, leftArrow, rightArrow)}
                  ref={rightArrow}>
                  <IconButton icon="arrowSlider" />
               </div>

               <div className="blocks">
                  {categories?.map((item: CategoryItem, idx) => (
                     <div data-id={`${idx}`} key={idx}>
                        {item.title && <div className="title">{item.title}</div>}

                        {item.description && <div className="description">{item.description}</div>}

                        {item.services.map((service: MarketLocationService) => (
                           <SelectServiceRow
                              key={service.id}
                              currentService={service}
                              allServices={item.services}
                              categories={categories}
                              bookingLimit={bookingLimit}
                           />
                        ))}
                     </div>
                  ))}
               </div>
            </>
         ) : (
            <SC.ServicesWrapper>
               <SC.ButtonsContainer>
                  {categories?.map((item: CategoryItem) => (
                     <SC.ButtonService
                        theme={{ selected: item.title === viewService }}
                        onClick={() => setActiveService(item.title)}
                        key={item.title}
                        id={`service_group_${item.title}`}>
                        <Text mb="none" size={isMobile ? 'small' : 'base'} weight="semiBold" className="align-center">
                           <div className="mr-sm">{item.title}</div>
                           {item.services.length}
                        </Text>
                     </SC.ButtonService>
                  ))}
               </SC.ButtonsContainer>

               <SC.ServicesBlock>
                  {description && (
                     <Text mb="mlarge" size="base" colorType="brown">
                        {description}
                     </Text>
                  )}

                  {!!services?.length &&
                     services.map((item: MarketLocationService) => (
                        <SelectServiceRow
                           key={item.id}
                           currentService={item}
                           categories={categories}
                           allServices={services}
                           bookingLimit={bookingLimit}
                        />
                     ))}
               </SC.ServicesBlock>
            </SC.ServicesWrapper>
         )}
      </>
   )
}
