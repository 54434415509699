import './style.less'

import { useQuery } from '@apollo/client'
import { Button, CustomIcon, formatTime, Text } from '@plandok/core'
import { getDateFnsLocale, IntlLabel, ReactIntl } from '@plandok/i18n'
import { Alert } from 'antd'
import { add, differenceInCalendarWeeks, Locale } from 'date-fns'
import { filter, find, map } from 'lodash'
import React, { useContext, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { FieldRenderProps } from 'react-final-form'

import { MarketPartnerLocation, MarketTimeSlots, query } from '../../ghql'
import { formatDates } from '../../helpers/date/booking-time'
import { useStore } from '../../store'
import DatePickerHeader from '../DatePickerHeader'
import DatePickerInput from '../DatePickerInput'
import DatePickerMobileHeader from '../DatePickerMobileHeader'
import InfinitySpinner from '../InfinitySpinner'
import WeekDatePicker from '../WeekDatePicker'
import * as SC from './styles'

type Input = {
   checked: undefined
   name: string
   onBlur: () => void
   onChange: () => void
   onFocus: () => void
   value: string
}

type BookTimeSelectProps = {
   values: MarketPartnerLocation
   input: Input
   isMobile: boolean
}

type TimeSlotsData = {
   timeSlots: MarketTimeSlots[]
   loading?: boolean
   error?: string
}

export type RenderCustomHeaderProps = {
   date: Date
   decreaseMonth: () => void
   increaseMonth: () => void
}

export default function BookTimeSelect({ values, input, isMobile }: FieldRenderProps<BookTimeSelectProps>) {
   const formValues = useStore(state => state.formValues)
   const serviceIds = useStore(state => state.serviceIds)
   const employeeId = useStore(state => state.employeeId)

   const pickerRef = useRef<DatePicker>(null)
   const { lang } = useContext(ReactIntl)

   const { timeFormat, allowEmployeeSelection, latestAppointmentTime } = values?.partner
   const today = new Date()
   const MONTHS_COUNT = parseInt(latestAppointmentTime?.match(/\d+/) ?? 0)

   const [isCalendarOpen, setIsCalendarOpen] = useState(false)
   const [isDaySelected, setIsDaySelected] = useState(false)
   const [selectDate, setSelectDate] = useState(today)
   const [dateIndex, setDateIndex] = useState(0)

   const setEndDate = add(today, { months: MONTHS_COUNT })
   const localeConfig = getDateFnsLocale(lang)
   const selectedDate = formatDates(selectDate)
   const context = { language: lang }
   const { onChange } = input

   const variables = {
      context,
      locationSlug: formValues?.slug,
      startDate: formatDates(today),
      endDate: formatDates(setEndDate),
      employeeId,
      serviceIds: serviceIds,
   }

   const {
      data: timeSlotsData,
      loading: timesSlotsLoading,
      error,
   } = useQuery<TimeSlotsData>(query.MARKET_TIME_SLOTS, { variables })

   const timeSlotsForSelectedDate = find(timeSlotsData?.timeSlots, ({ date }) => date === selectedDate)
   const selectedDayTimeSlots = timeSlotsForSelectedDate?.timeSlots?.slice().sort((a, b) => a - b)
   const workingDays: string[] = map(
      filter(timeSlotsData?.timeSlots, ({ timeSlots }) => timeSlots?.length) as MarketTimeSlots[],
      ({ date }) => date,
   )

   const baseLocaleConfig: Locale = {
      ...localeConfig,
      // overriding base date-fns locale to have weekStartsOn - 1 (Monday) instead of Sunday
      options: { weekStartsOn: 1, firstWeekContainsDate: 1 },
   }

   const isWeekday = (date: Date) => Boolean(workingDays && workingDays.includes(formatDates(date)))
   const helperForSelectDate = (day: Date) => setSelectDate(day)
   const toggleAction = () => setIsCalendarOpen(!isCalendarOpen)
   const onClose = () => pickerRef.current?.setOpen(false)

   const onSlotSelect = (timeSlot: number) => {
      const formatDate = formatDates(selectDate)

      onChange({ time: timeSlot, date: formatDate })
      values.onSetStep()
   }

   const changePickerByDay = (day: any) => {
      const selectDay = document.querySelector(`.day[data-date="${formatDates(day)}"]`)
      const timeSlotsContainer = document.querySelector('#timeSlotsContainer')

      helperForSelectDate(day)

      if (!isMobile) {
         const index = differenceInCalendarWeeks(day, new Date(), { weekStartsOn: 1 })
         setDateIndex(index)

         if (timeSlotsContainer) {
            timeSlotsContainer.scrollTo({
               top: 0,
               behavior: 'smooth',
            })
         }
      }

      if (selectDay) {
         selectDay.scrollIntoView({
            behavior: 'smooth',
            inline: 'center',
            block: 'nearest',
         })
      }

      setIsDaySelected(true)
      setIsCalendarOpen(!isCalendarOpen)
   }

   return (
      <SC.Container>
         <WeekDatePicker
            currentDate={selectDate}
            helperForSelectDate={helperForSelectDate}
            setIsDaySelected={setIsDaySelected}
            workingDays={workingDays}
            dateIndex={dateIndex}
            setDateIndex={setDateIndex}
         />

         {timesSlotsLoading ? (
            <InfinitySpinner width="100%" height="100%" background={isMobile ? '#f4f5f6' : '#ffffff'} />
         ) : (
            <>
               {error ? (
                  <Alert
                     message={<IntlLabel label={error.toString()} />}
                     type="error"
                     style={{ marginBottom: '10px' }}
                  />
               ) : (
                  <SC.CustomCard>
                     {isMobile ? (
                        <DatePicker
                           ref={pickerRef}
                           renderCustomHeader={(props: RenderCustomHeaderProps) => (
                              <DatePickerMobileHeader props={props} onClose={onClose} />
                           )}
                           selected={selectDate}
                           onChange={changePickerByDay}
                           withPortal
                           minDate={today}
                           useWeekdaysShort={true}
                           filterDate={isWeekday}
                           locale={baseLocaleConfig}
                           customInput={
                              <div>
                                 <DatePickerInput
                                    isDaySelected={isDaySelected}
                                    selectDate={selectDate}
                                    isCalendarOpen={isCalendarOpen}
                                    isMobile={isMobile}
                                 />
                              </div>
                           }
                        />
                     ) : (
                        <div className="position-relative">
                           <DatePickerInput
                              isDaySelected={isDaySelected}
                              selectDate={selectDate}
                              isCalendarOpen={isCalendarOpen}
                              isMobile={isMobile}
                              toggleAction={toggleAction}
                           />

                           {isCalendarOpen && (
                              <DatePicker
                                 renderCustomHeader={DatePickerHeader}
                                 selected={selectDate}
                                 onChange={changePickerByDay}
                                 inline
                                 minDate={today}
                                 useWeekdaysShort={true}
                                 filterDate={isWeekday}
                                 locale={baseLocaleConfig}
                              />
                           )}
                        </div>
                     )}

                     {!!selectedDayTimeSlots?.length ? (
                        <SC.ButtonsWithTimeContainer theme={{ allowEmployeeSelection }} id="timeSlotsContainer">
                           {selectedDayTimeSlots?.map(timeSlot => (
                              <Button key={timeSlot} onClick={() => onSlotSelect(timeSlot)}>
                                 <div className="justify-align">
                                    <Text mb="none" colorType="black" size="base">
                                       {formatTime(timeSlot, timeFormat)}
                                    </Text>
                                    <CustomIcon type="arrowTimeBlock" />
                                 </div>
                              </Button>
                           ))}
                        </SC.ButtonsWithTimeContainer>
                     ) : (
                        <SC.FullyBookedContent>
                           <Text label="market.ob.timeSlots.fullyBooked.title" colorType="black" size="small" />
                           <Text
                              label="market.ob.timeSlots.choseAnotherDay.title"
                              colorType="black"
                              size="small"
                              mb="none"
                           />
                        </SC.FullyBookedContent>
                     )}
                  </SC.CustomCard>
               )}
            </>
         )}
      </SC.Container>
   )
}
