import { Text } from '@plandok/core'
import React from 'react'

import infinitySpinner from './spinner.gif'
import * as SC from './styles'

type InfinitySpinnerProps = {
   width?: string
   height?: string
   background?: string
}

export default function InfinitySpinner({
   width = '100vw',
   height = '100vh',
   background = '#f4f5f6',
}: InfinitySpinnerProps) {
   return (
      <SC.SpinnerContainer theme={{ width, height, background }}>
         <div className="align-center">
            <img src={infinitySpinner} alt="∞" />
            <Text
               mb="none"
               label="market.ob.pleaseWait.title"
               colorType="black"
               weight="medium"
               ml="xsmall"
               size="base"
            />
         </div>
      </SC.SpinnerContainer>
   )
}
