import { IconButton, Text } from '@plandok/core'
import React from 'react'

import { RenderCustomHeaderProps } from '../BookTimeSelect'
import DatePickerHeader from '../DatePickerHeader'
import * as SC from './styles'

type MobileHeaderPickerProps = {
   props: RenderCustomHeaderProps
   onClose: () => void
}

export default function DatePickerMobileHeader({ props, onClose }: MobileHeaderPickerProps) {
   return (
      <>
         <SC.MobileHeaderPicker>
            <Text mb="none" label="input.pickDate.title" colorType="darkBrown" weight="medium" size="large" />
            <IconButton icon="close" onClick={onClose} size="20px" />
         </SC.MobileHeaderPicker>

         <DatePickerHeader {...props} />
      </>
   )
}
