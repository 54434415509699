import { Button, CustomIcon, Text } from '@plandok/core'
import { LanguageNameMapping, ReactIntl } from '@plandok/i18n'
import React, { useContext } from 'react'

import * as SC from './styles'

type LocationHeaderProps = {
   bookNow: () => void
   openSelectLanguage: () => void
   // reviews: boolean;
}

export const LocationHeader = ({ bookNow, openSelectLanguage }: LocationHeaderProps) => {
   const { lang } = useContext(ReactIntl)

   const goToAbout = () => document.getElementById('about')?.scrollIntoView()
   // const goToReviews = () => document.getElementById('reviews')?.scrollIntoView()

   return (
      <SC.ContainerHeader>
         <SC.ContainerButtons>
            <Button label="market.btn.bookNow" upperCase={false} bold onClick={bookNow} />
            {/* reviews && <Button label="market.btn.reviews" upperCase={false} bold onClick={goToReviews} /> */}
            <Button label="market.location.about.title" upperCase={false} bold onClick={goToAbout} />
            <Button onClick={openSelectLanguage}>
               <CustomIcon type="globe" />
               <Text mb="none" ml="xsmall" weight="semiBold" size="small" colorType="black">
                  {LanguageNameMapping[lang]}
               </Text>
            </Button>
         </SC.ContainerButtons>
      </SC.ContainerHeader>
   )
}
