import { Button, Text } from '@plandok/core'
import styled from 'styled-components'

export const ButtonGetDirection = styled(Button)`
   margin-top: ${({ theme }) => (theme.isPhone ? '15px' : '8px')};
   border: ${({ theme }) => (theme.isPhone ? '1px solid #7F8596 !important' : 'none')};
   border-bottom: ${({ theme }) => !theme.isPhone && '1px solid #3B71FE !important'};
   border-radius: ${({ theme }) => !theme.isPhone && 'initial'};
   max-height: ${({ theme }) => (theme.isPhone ? '40px' : '30px')};
   display: flex;
   align-items: center;
   padding: ${({ theme }) => (theme.isPhone ? '10px 19px 10px 24px' : '6px 0')};
   background-color: #fcfcfd !important;

   div {
      line-height: ${({ theme }) => (theme.isPhone ? '21px' : '24px')};
      color: ${({ theme }) => (theme.isPhone ? 'black' : '#3B71FE')};
   }

   svg {
      margin-left: ${({ theme }) => (theme.isPhone ? '8px' : '6px')};
   }
`

export const ContainerLocation = styled.div`
   display: flex;
   align-items: ${({ theme }) => !theme.isPhone && 'center'};
   width: ${({ theme }) => (theme.isPhone ? 'auto' : '450px')};
   height: 100%;
   button {
      background-color: #3b71fe;
      border: none;
   }

   @media screen and (max-width: 1024px) {
      width: ${({ theme }) => (theme.isPhone ? 'auto' : 'calc(100% - 2rem)')};
      margin: 0 auto;
   }
`

export const Title = styled(Text)`
   font-size: ${({ theme }) => {
      return `${theme.lengthOfLongestWord > 13 ? '40px' : '60px'}`
   }};
   word-break: ${({ theme }) => {
      return `${theme.lengthOfLongestWord > 19 ? 'break-all' : 'unset'}`
   }};
   line-height: 120%;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
   margin-bottom: 35px;

   @media screen and (max-width: 1024px) {
      margin-bottom: 0;
      position: absolute;
      top: ${({ theme }) => `calc(100vw - ${theme.height < 50 ? 85 : 120}px)`};
      width: calc(100vw - 2rem);
      color: #fcfcfd;
      text-align: start;
      font-size: ${({ theme }) => (theme.height > 95 ? '32px' : '38px')};
   }
`

export const InfoItem = styled.div`
   display: flex;
   align-items: center;
   margin-bottom: 28px;
   a {
      text-decoration: none;
      margin-left: 13px;
      color: #2d2825;
      font-size: 14px;
   }
`

export const WrapperAddress = styled.div`
   margin: auto 0;
   width: 100%;
`

export const LocationAddress = styled.div`
   display: flex;
   text-align: start;
   margin-bottom: ${({ theme }) => !theme.isPhone && '14px'};
   max-width: ${({ theme }) => theme.isPhone && '387px'};

   @media screen and (max-width: 1024px) {
      margin-bottom: 24px;
      max-width: ${({ theme }) => theme.isPhone && '250px'};
      svg:first-child {
         path {
            fill: ${({ theme }) => !theme.isPhone && '#353945'};
         }
      }
   }
`

export const LocationAddressDetails = styled.div`
   border-left: 1px solid #777e90;
   margin-top: 50px;
   padding-left: 15px;
`
