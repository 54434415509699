import { Button, CustomIcon } from '@plandok/core'
import { useElements, useStripe } from '@stripe/react-stripe-js'
import { PaymentFooterProps } from 'pages/BookFlowPage/components/steps/PaymentStep/components/PaymentFooter/PaymentFooter'
import * as SC from 'pages/BookFlowPage/components/steps/PaymentStep/components/PaymentFooter/styles'
import React, { useRef, useState } from 'react'
import { useStore } from 'store'

interface IStripePaymentFooter extends PaymentFooterProps {
   setError: any
   totalPrice: number
}

function StripePaymentFooter(props: IStripePaymentFooter) {
   const submitButtonRef = useRef<HTMLButtonElement>(null)
   const [isLoading, setIsLoading] = useState<boolean>(false)

   const setPaymentMethod = useStore(state => state.setPaymentMethod)

   const elements = useElements()

   const stripe = useStripe()

   const handleCreatePaymentMethod = async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      event.stopPropagation()
      if (elements) {
         const cardNumber = elements.getElement('cardNumber')

         if (cardNumber && stripe) {
            const { error, paymentMethod } = await stripe.createPaymentMethod({
               type: 'card',
               card: cardNumber,
            })

            if (error) {
               console.log('Payment method creation error', error)
               props.setError(error)
               return false
            } else {
               setPaymentMethod(paymentMethod?.id ?? '')
               return true
            }
         }
      }
      return false
   }

   const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
      setIsLoading(true)
      const isMethodCreated = await handleCreatePaymentMethod(event)
      setIsLoading(false)
      props.values.onNext(props.totalPrice)

      if (submitButtonRef.current && isMethodCreated) {
         submitButtonRef.current.click()
      }
   }

   return (
      <SC.BottomControl>
         <Button
            type="primary"
            label={props.isMobile ? 'btn.next' : 'payment.payNow.title'}
            loading={props.values.submitting || isLoading}
            htmlType="button"
            onClick={handleClick}
            upperCase={false}>
            {props.isMobile && <CustomIcon type="arrowRightFooter" />}
         </Button>
         <button ref={submitButtonRef} type="submit" style={{ display: 'none' }}>
            Submit
         </button>
      </SC.BottomControl>
   )
}

export default StripePaymentFooter
