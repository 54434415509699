import { Checkbox as AntCheckbox, Spin } from 'antd'
import styled from 'styled-components'

export const CustomOverlay = styled(Spin)`
   max-height: max-content !important;
   z-index: 1 !important;
   .ant-spin-dot {
      display: none;
   }
`

export const HelpWrapper = styled.div`
   padding-right: 9px;
   :hover {
      cursor: pointer;
   }

   :hover + div {
      display: block;
   }
`

export const LimitMessageContainer = styled.div`
   border-radius: 8px;
   background: #101828;
   padding: 12px;
   display: none;
   div {
      font-family:
         Work Sans,
         sans-serif;
      line-height: 20px;
      :last-child {
         color: #ffffffb3;
      }
   }
`

export const HelpContainer = styled.div`
   width: 320px;
   display: flex;
   align-items: center;
   position: absolute;
   z-index: 2;
   top: 50%;
   right: ${({ theme }) => (theme.isMobile ? 'calc(100vw - 625px)' : '-6%')};
   transform: translate(-81%, -50%);
`

export const ServiceItemContainer = styled.div`
   padding: 20px 24px 24px;
   display: flex;
   flex-direction: row;
   border-radius: 10px;
   margin-bottom: 8px;
   align-items: center;
   background-color: #fcfcfd;
   cursor: pointer;
   border: ${({ theme }) => (theme.selected ? '1px solid #3B71FE' : 'none')};
   box-shadow: ${({ theme }) => (theme.selected ? 'none' : '0px 5px 10px rgba(126, 154, 182, 0.25)')};
   .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #3b71fe !important;
      border-color: #3b71fe !important;
   }
   .ant-checkbox-wrapper:hover .ant-checkbox-inner,
   .ant-checkbox:hover .ant-checkbox-inner {
      border-color: #3b71fe !important;
   }
   .ant-checkbox-input {
      width: 100%;
      height: 100%;
   }

   @media screen and (max-width: 1024px) {
      background-color: #ffffff;
      padding: 12px 16px;
      align-items: start;
      .cm-checkbox {
         margin-top: 6px;
      }
   }
`

export const Block = styled.div`
   width: 38px;
`

export const Checkbox = styled(AntCheckbox)`
   margin: 10px;
`

export const ServiceTitle = styled.div`
   display: flex;
   justify-content: space-between;
   margin-bottom: 4px;
   div:nth-child(2) {
      text-align: end;
      min-width: max-content;
   }

   @media screen and (max-width: 1024px) {
      margin-bottom: 2px;
   }
`

export const ServiceDuration = styled.div`
   display: flex;
   margin-bottom: 4px;
   line-height: 21px;
   div {
      color: rgba(45, 40, 37, 0.6);
   }

   @media screen and (max-width: 1024px) {
      margin-bottom: 2px;
   }
`
