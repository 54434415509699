import { removeTimeZone } from '@plandok/core'
import { LanguageFnsConfig, ReactIntl } from '@plandok/i18n'
import { format } from 'date-fns'
import { useContext } from 'react'

const useFormatDate = (pattern: string, date?: Date) => {
   const { lang } = useContext(ReactIntl)

   if (!date) {
      return
   }

   return format(removeTimeZone(new Date(date)), pattern, { locale: LanguageFnsConfig[lang] })
}

export default useFormatDate
