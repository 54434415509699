import './style.less'

import { IconButton, monthOptions, Text } from '@plandok/core'
import { IntlDate } from '@plandok/i18n'
import { getMonth } from 'date-fns'
import React from 'react'

import { RenderCustomHeaderProps } from '../BookTimeSelect'

export default function DatePickerHeader({ decreaseMonth, increaseMonth, date }: RenderCustomHeaderProps) {
   const translatedMonth = monthOptions.find(({ code }: { code: number }) => code === getMonth(date))?.name

   const ArrowPrevious = () => (
      <div className="data-book-time-header-button" onClick={decreaseMonth}>
         <IconButton icon="arrowSlider" />
      </div>
   )

   const ArrowNext = () => (
      <div className="data-book-time-header-button" onClick={increaseMonth}>
         <IconButton icon="arrowSlider" />
      </div>
   )

   return (
      <div className="justify-align">
         <ArrowPrevious />

         <div className="d-flex">
            <Text label={translatedMonth} mb="none" colorType="black" size="small" weight="medium" />
            <Text mb="none" colorType="black" size="small" weight="medium" ml="xxsmall">
               <IntlDate date={date} dateFormat="yyyy" />
            </Text>
         </div>

         <ArrowNext />
      </div>
   )
}
