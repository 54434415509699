import { Button, mediaSmDown, Text } from '@plandok/core'
import styled, { css } from 'styled-components'

export const CancelAppointmentContainer = styled.div`
   text-align: center;
   svg {
      margin-bottom: 11px;

      ${mediaSmDown(css`
         margin-bottom: 0;
         width: 64px;
         height: 67px;
      `)}
   }
`

export const SansText = styled(Text)`
   font-family: 'DM Sans', sans-serif;
`

export const ButtonsContainer = styled.div`
   button {
      width: 100%;
      min-height: 58px;
      color: #353945;
      &:hover,
      &:focus {
         color: #353945;
      }
      &:first-child {
         margin-bottom: 6px;
         background: #e6e8ec;
      }
      &:last-child {
         box-shadow: none;
      }

      ${mediaSmDown(css`
         min-height: 52px;
      `)}
   }
`

export const SuccessCancelAppointmentContainer = styled.div`
   text-align: center;
   svg {
      margin-bottom: 4px;

      ${mediaSmDown(css`
         margin-bottom: 3px;
         width: 64px;
         height: 67px;
      `)}
   }
`

export const BtnClose = styled(Button)`
   color: #353945 !important;
   background: #e6e8ec;
   max-width: 173px;
`
