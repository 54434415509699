import FlowStatusBlock from 'components/FlowStatusBlock'
import { MOBILE_BREAKPOINT } from 'constants/breakpoints'
import { MarketLocationService, MarketLocationServiceGroups } from 'ghql'
import useMediaQuery from 'hooks/screen/use-media-query'
import React, { useEffect, useState } from 'react'
import { useStore } from 'store'

import { BookFlowFooter } from './components/BookFlowFooter'
import { BookFlowServices } from './components/BookFlowServices'

export default function ServiceSelectStep({ values }: any) {
   const [categories, setCategories] = useState<any[]>()

   const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`)

   const serviceIds = useStore(state => state.serviceIds)

   const { serviceLimit } = values?.partner || {}
   const serviceGroups: MarketLocationServiceGroups[] = values?.serviceGroups
   const mappedServices: MarketLocationService[] = []
   const selectedServices: MarketLocationService[] = []

   serviceGroups?.map(({ services }) => mappedServices.push(...services))

   serviceIds?.forEach(serviceId => {
      const filteredServices = mappedServices?.filter(({ id }) => id === serviceId)
      selectedServices.push(...filteredServices)
   })

   useEffect(() => {
      let newCategories = serviceGroups
         ?.filter(({ services }) => services.length)
         ?.map(serviceGroup => {
            const services: MarketLocationService[] = serviceGroup?.services

            return {
               title: serviceGroup.name,
               description: serviceGroup.description,
               services,
            }
         })

      newCategories && setCategories(newCategories)
   }, [serviceGroups])

   const Footer = <BookFlowFooter values={values} selectedServices={selectedServices} isMobile={isMobile} />

   return (
      <div className="justify-between">
         {!!categories?.length && (
            <>
               <BookFlowServices categories={categories} bookingLimit={serviceLimit} />
               {!isMobile && <FlowStatusBlock values={values} />}
            </>
         )}
         {isMobile ? !!selectedServices.length && Footer : Footer}
      </div>
   )
}
