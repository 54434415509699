import { Card, mediaSmDown, Text } from '@plandok/core'
import styled, { css } from 'styled-components'

export const PageContainer = styled.div`
   background: #f4f5f6;
   min-height: 100vh;

   ${mediaSmDown(css`
      background: #ffffff;
   `)}
`

export const HeaderContainer = styled.header`
   width: 100vw;
   display: flex;
   justify-content: end;
   padding: 43px 46px 18px;

   button {
      padding: 0;
      svg {
         width: 24px;
         height: 24px;

         path {
            stroke: #373737;
         }
      }
   }
`

export const CardAppointment = styled(Card)`
   box-shadow: 0px 5px 10px rgba(126, 154, 182, 0.25);
   border-radius: 10px;
   max-width: 541px;
   border: none;
   margin: 0 auto;
   padding: 32px 64px;
   text-align: center;

   ${mediaSmDown(css`
      box-shadow: none;
      padding: 43px 31px;
   `)}
`

export const CalendarIconContainer = styled.div`
   svg {
      margin: 0 auto 25px;

      ${mediaSmDown(css`
         width: 64px;
         height: 67px;
      `)}
   }
`

export const DetailsAppointment = styled.div`
   display: flex;
   justify-content: space-between;
   padding-bottom: 32px;
   border-bottom: 1px solid #e6e8ec;

   ${mediaSmDown(css`
      flex-direction: column-reverse;
      padding-bottom: 0;
      border-bottom: none;
   `)}
`

export const ImageContainer = styled.div`
   background-image: url(${({ theme }) => theme.background});
   border-radius: 5px;
   width: 130px;
   height: 130px;
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;

   ${mediaSmDown(css`
      margin: 0 auto 20px;
      display: flex;
      align-items: end;
      width: 312px;
      height: 152px;

      div {
         border-radius: 0 0 5px 5px;
         text-align: center;
         background: #353945;
         opacity: 0.92;
         width: 100%;
         line-height: 21px;
         padding: 5px;
      }
   `)}
`

export const AppointmentDateContainer = styled.div`
   width: 259px;
   text-align: start;

   ${mediaSmDown(css`
      width: 312px;
      margin: 0 auto;
      padding-bottom: 32px;
      border-bottom: 1px solid #e6e8ec;
   `)}
`

export const AppointmentDateTitle = styled(Text)`
   color: #777e90;
   margin-bottom: 2px;
`

export const TimeContainer = styled.div`
   display: flex;
   align-items: center;
   margin-bottom: 14px;
`

export const AssignEmployeeContainer = styled.div`
   display: flex;
   align-items: center;
   margin-top: 12px;
`

export const ServicesContainer = styled.div`
   padding: 24px 0 4px;
   border-bottom: 1px solid #e6e8ec;
   margin-bottom: 24px;
   text-align: start;

   ${mediaSmDown(css`
      width: 312px;
      margin: 0 auto 24px;
   `)}
`

export const ServiceContainer = styled.div`
   margin-bottom: 12px;
   div {
      :nth-child(1) {
         div:nth-child(2) {
            white-space: nowrap;
            margin-left: 10px;
         }
      }
      :nth-child(2) {
         div {
            color: #777e90;
         }
      }
   }
`

export const TotalInfo = styled.div`
   display: flex;
   justify-content: end;
   margin-bottom: 40px;
   div:last-child {
      margin-left: 60px;
      white-space: nowrap;
   }
`

export const ButtonsContainer = styled.div`
   button {
      width: 100%;
      min-height: 58px;
      color: #353945;
      &:hover,
      &:focus {
         color: #353945 !important;
      }
      &:first-child {
         margin-bottom: 6px !important;
         background: #e6e8ec !important;
      }
      &:last-child {
         box-shadow: none !important;

         &:hover {
            background: #e6e8ec !important;
         }
      }
   }
`

export const FooterContainer = styled.footer`
   height: 102px;
`
