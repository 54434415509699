import { Button, CustomIcon } from '@plandok/core'
import React, { useRef } from 'react'

import * as SC from './styles'

export interface PaymentFooterProps {
   values: any
   isMobile: boolean
}

export const PaymentFooter = ({ values, isMobile }: PaymentFooterProps) => {
   const submitButtonRef = useRef<HTMLButtonElement>(null)

   const handleClick = async () => {
      if (submitButtonRef.current) {
         values.onNext()
         submitButtonRef.current.click()
      }
   }

   return (
      <SC.BottomControl>
         <Button
            type="primary"
            label={isMobile ? 'btn.next' : 'market.btn.bookNow'}
            loading={values.submitting}
            htmlType="button"
            onClick={handleClick}
            upperCase={false}>
            {isMobile && <CustomIcon type="arrowRightFooter" />}
         </Button>
         <button ref={submitButtonRef} type="submit" style={{ display: 'none' }}>
            Submit
         </button>
      </SC.BottomControl>
   )
}
