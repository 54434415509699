import { mediaSmDown } from '@plandok/core'
import styled, { css } from 'styled-components'

type HeaderItemProps = {
   active: boolean
   disabled: boolean
}

export const WrapperMonthTitle = styled.div`
   margin: 16px 59px 0;
   display: flex;
   justify-content: space-between;
   align-items: center;
   div:last-child {
      div:last-child {
         margin-left: 20px;
      }
   }

   @media screen and (max-width: 1024px) {
      width: calc(100vw - 40px);
      margin: 0 auto;
   }
`

export const WeekViewWrapperMobile = styled.div`
   overflow: auto;
   width: calc(100vw - 40px);
   display: flex;
   margin: 0 auto 12px;
   ::-webkit-scrollbar {
      display: none;
   }
`

export const WeekViewWrapperDesktop = styled.div`
   display: flex;
   width: 362px;
   margin: 0 auto;
   justify-content: space-between;
`

export const DayViewWrapper = styled.div`
   min-width: calc(100% / 7);
`

export const HeaderItem = styled.div<HeaderItemProps>`
   width: 50px;
   height: 56px;
   border-radius: 5px;
   background: ${({ active }) => (active ? '#225FFE' : '#ffffff')};
   padding: ${({ active }) => (active ? '8px 11px' : '8px 7px')};
   cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
   text-align: center;
   div {
      font-size: 12px;
      color: ${({ disabled, active }) => (disabled ? '#989DAB' : active ? '#ffffff' : '#777E90')};
      text-transform: ${({ active }) => !active && 'lowercase'};
      font-weight: ${({ active }) => active && '500'};
   }
   span {
      font-size: 16px;
      color: ${({ disabled, active }) => (disabled ? '#989DAB' : active ? '#ffffff' : '#000000')};
      font-weight: 500;
   }

   ${mediaSmDown(css`
      margin: 0 auto;
      padding: 8px 0;
      width: 40px;
   `)}
`

export const Header = styled.div`
   display: flex;
   max-width: 416px;
   margin: 0 auto;
   align-items: center;

   svg {
      width: 13px;
      height: 11px;
   }
`

export const ArrowNext = styled.div`
   cursor: pointer;
   svg {
      transform: rotate(180deg);
   }
`
