import { Text } from '@plandok/core'
import styled from 'styled-components'

export const HoursContainer = styled.div`
   display: flex;
   align-items: center;

   @media screen and (max-width: 1024px) {
      svg {
         path:first-child {
            fill: #353945;
         }
      }
   }
`

export const FullWeekHoursContainer = styled.div`
   text-align: center;

   @media screen and (max-width: 1024px) {
      margin: 24px 0 48px;
   }
`

export const SansText = styled(Text)`
   font-family: 'DM Sans', sans-serif;
`

export const FullWeekHoursWrapper = styled.div`
   display: flex;
   min-width: max-content;
   justify-content: space-between;
`
