import { Avatar } from 'antd'
import styled from 'styled-components'

export const StaffItem = styled.div`
   display: flex;
   align-items: center;
   padding: 3px 28px;
   justify-content: space-between;
   width: 100%;
`

export const CustomAvatar = styled.div`
   display: flex;
   align-items: center;

   @media screen and (max-width: 1024px) {
      svg {
         width: 40px;
         height: 40px;
      }
   }
`

export const StyledAvatar = styled(Avatar)`
   background: #a2a0ff;
   font-size: 14px !important;
   color: #ffffff;
`
