import { useEffect } from 'react'

import { useStore } from '../../store'

declare global {
   interface Window {
      dataLayer: any[]
   }
}

const GoogleAnalytics = () => {
   const { googleTrackerId } = useStore(state => state.trackerIds)

   useEffect(() => {
      if (googleTrackerId) {
         console.log(googleTrackerId)
         const script = document.createElement('script')
         script.async = true
         script.src = `https://www.googletagmanager.com/gtag/js?id=${googleTrackerId}`
         const referenceElement = document.head.querySelector('script')
         if (referenceElement) {
            const nextElement = referenceElement.nextSibling
            document.head.insertBefore(script, nextElement)
         } else {
            document.head.appendChild(script)
         }

         script.onload = () => {
            window.dataLayer = window.dataLayer || []
            function gtag(...args: any[]) {
               window.dataLayer.push(args)
            }
            gtag('js', new Date())
            gtag('config', googleTrackerId)
            gtag('config', googleTrackerId, {
               page_path: '/en/absque-metus',
            })
         }
      }
   }, [googleTrackerId])

   return null
}

export default GoogleAnalytics
