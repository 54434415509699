import styled from 'styled-components'

export const BottomControl = styled.footer`
   position: absolute;
   bottom: 0;
   left: 0;
   right: 0;
   display: flex;
   padding: 24px 0 48px;
   justify-content: center;
   button {
      min-width: 208px;
      padding: 23px 25px;
      background: #225ffe;
      border: none;
      font-weight: 500;
      svg {
         margin-left: 5px;
      }
   }

   @media screen and (max-width: 1024px) {
      height: 40px;
      border-top: 1px solid #e6e8ec;
      padding: 24px 0;
      background-color: #fcfcfd;
      button {
         min-width: 170px;
         font-weight: 600;
      }
   }
`
