import { ApolloClient, createHttpLink, InMemoryCache, MutationOptions, QueryOptions } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { sha256 } from 'crypto-hash'

import * as helpers from './helpers'
import * as mutation from './mutations'
import * as query from './queries'

const httpLink = createHttpLink({
   uri: process.env.REACT_APP_MARKET_GRAPHQL_API,
})
const persistedQueriesLink = createPersistedQueryLink({ sha256, useGETForHashedQueries: true })
const graphqlClient = new ApolloClient({
   link: persistedQueriesLink.concat(httpLink),
   cache: new InMemoryCache(),
   defaultOptions: {
      watchQuery: {
         fetchPolicy: 'network-only',
      },
      query: {
         fetchPolicy: 'network-only',
      },
   },
})

/**
 * Function execute mutation without hooks
 * @example  mutate(mutation.UPDATE_CLOSED_DATE)(formValues)
 */
const mutate =
   <OperationVariables = any>(mut: MutationOptions['mutation'], isContext?: boolean) =>
   (input?: OperationVariables, options?: Omit<MutationOptions<any, OperationVariables | undefined>, 'mutation'>) =>
      graphqlClient
         .mutate({ ...options, mutation: mut, variables: isContext ? input : { input }, ...options } as any)
         .then(helpers.mapMutationResponseResult)
         .catch(helpers.mapMutationErrorResponse)

/**
 * Function execute query without hooks
 * @example  query(queryCall.CLOSED_DATES)
 */
const queryCall =
   <TVariables = any>(query: QueryOptions['query']) =>
   (input?: TVariables, options?: Omit<MutationOptions<TVariables, any>, 'query'>) =>
      graphqlClient
         .query({ ...options, query, variables: { input, ...options } })
         .then(helpers.mapQueryResponseResult)
         .then(helpers.mapQueryErrorResponse)

export { graphqlClient, mutate, mutation, query, queryCall }
export * from './types'
