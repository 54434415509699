import styled from 'styled-components'

export const SelectCalendar = styled.div`
   height: 56px;
   background: #fcfcfd;
   border: 1px solid #d8dadf;
   border-radius: 5px;
   width: 362px;
   margin: 12px auto;
   cursor: pointer;
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding: 5px 26px 6px 20px;
   svg {
      g {
         path {
            fill: #000000;
         }
      }
   }
   div {
      div {
         div:first-child {
            color: #225ffe;
         }
         div:last-child {
            color: #777e90;
            text-align: start;
         }
      }
   }

   @media screen and (max-width: 1024px) {
      width: calc(100vw - 120px);
      margin: 0 auto;
   }
`
