import { CustomIcon, Text } from '@plandok/core'
import { format } from 'date-fns'
import React from 'react'

import * as SC from './styles'

type DatePickerInputProps = {
   isDaySelected: boolean
   selectDate: Date
   isCalendarOpen: boolean
   isMobile: boolean
   toggleAction?: () => void
}

export default function DatePickerInput({
   isDaySelected,
   selectDate,
   isCalendarOpen,
   isMobile,
   toggleAction,
}: DatePickerInputProps) {
   return (
      <SC.SelectCalendar onClick={toggleAction && toggleAction}>
         <div className="align-center">
            <CustomIcon type="selectFromCalendar" />
            <div>
               <Text label="calendar.btn.selectFromCalendar" mb="none" size="xsmall" ml="small" weight="medium" />
               <Text mb="none" ml="small" size="base" weight="medium">
                  {isDaySelected ? format(selectDate, 'dd/MM/yy') : 'DD/MM/YY'}
               </Text>
            </div>
         </div>
         <CustomIcon type={isMobile ? 'arrowDown' : isCalendarOpen ? 'arrowUp' : 'arrowDown'} />
      </SC.SelectCalendar>
   )
}
