import { CustomIcon } from '@plandok/core'
import React, { useState } from 'react'

const TrashBinIcon = (props: any) => {
   const [isHovered, setIsHovered] = useState(false)

   const handleMouseEnter = () => {
      setIsHovered(true)
   }

   const handleMouseLeave = () => {
      setIsHovered(false)
   }

   return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} {...props} style={{ cursor: 'pointer' }}>
         {isHovered ? <CustomIcon type={'trashBinHovered'} /> : <CustomIcon type={'trashBin'} />}
      </div>
   )
}

export default TrashBinIcon
