import { CustomIcon, monthOptions, Text } from '@plandok/core'
import { IntlDate } from '@plandok/i18n'
import { MOBILE_BREAKPOINT } from 'constants/breakpoints'
import { getMonth, isSameDay } from 'date-fns'
import { formatDates, getWeekRange } from 'helpers/date/booking-time'
import useMediaQuery from 'hooks/screen/use-media-query'
import React, { useEffect, useRef, useState } from 'react'

import { handleHeaderItemClick, handleHorizontalScroll, isDayOff, translateWeekDays, weekdays } from './helpers'
import * as SC from './styles'

type WeekDatePickerProps = {
   currentDate: Date
   helperForSelectDate: (day: Date) => void
   setIsDaySelected: (isSelected: boolean) => void
   workingDays: string[]
   dateIndex: number
   setDateIndex: (index: number) => void
}

export default function WeekDatePicker({
   currentDate,
   helperForSelectDate,
   setIsDaySelected,
   workingDays,
   dateIndex,
   setDateIndex,
}: WeekDatePickerProps) {
   const year = currentDate.getFullYear()
   const month = currentDate.getMonth()

   const firstDay = formatDates(new Date(year, month, 1))
   const lastDay = formatDates(new Date(year, month + 4, 0))

   const [monthInView, setMonthInView] = useState<string>(formatDates(currentDate))
   const [dateRange, setDateRange] = useState([firstDay, lastDay])
   const [firDay, lasDay] = dateRange

   const weekWrapperRef = useRef<HTMLDivElement>(null)
   const currentDayRef = useRef<HTMLDivElement>(null)
   const weekWrapper = weekWrapperRef.current
   const currentDay = currentDayRef.current

   const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`)
   const daysRange = getWeekRange(dateIndex)
   const days = weekdays(new Date(firDay), new Date(lasDay))
   const currentMonth = isMobile ? new Date(monthInView) : daysRange?.find(day => day) || 0
   const translatedCurrentMonth = monthOptions.find(({ code }) => code === getMonth(currentMonth))?.name

   useEffect(() => {
      if (isMobile && currentDay) {
         currentDay.scrollIntoView({
            behavior: 'smooth',
            inline: 'start',
            block: 'nearest',
         })
      }
   }, [isMobile, currentDay])

   const goPrevious = () =>
      weekWrapper ? (weekWrapper.scrollLeft -= weekWrapper.clientWidth) : setDateIndex(dateIndex - 1)

   const goNext = () =>
      weekWrapper ? (weekWrapper.scrollLeft += weekWrapper.clientWidth) : setDateIndex(dateIndex + 1)

   const ArrowPrevious = () => (
      <div onClick={goPrevious} className="cursor-pointer">
         <CustomIcon type="arrowWeekPicker" />
      </div>
   )

   const ArrowNext = () => (
      <SC.ArrowNext onClick={goNext}>
         <CustomIcon type="arrowWeekPicker" />
      </SC.ArrowNext>
   )

   return (
      <>
         <SC.WrapperMonthTitle>
            <Text mb="small" size="base" colorType="black" label={translatedCurrentMonth} />
            {isMobile && (
               <div className="d-flex">
                  <ArrowPrevious />
                  <ArrowNext />
               </div>
            )}
         </SC.WrapperMonthTitle>

         {isMobile ? (
            <SC.WeekViewWrapperMobile
               ref={weekWrapperRef}
               onScroll={() =>
                  handleHorizontalScroll(setMonthInView, weekWrapper, lasDay, setDateRange, firDay, currentDay)
               }>
               {days.map(day => {
                  const active = isSameDay(day.date, currentDate)
                  const disabled = isDayOff(day.date, workingDays)

                  return (
                     <SC.DayViewWrapper key={day.actualDate}>
                        <SC.HeaderItem
                           active={active}
                           disabled={disabled}
                           ref={day.isToday ? currentDayRef : null}
                           onClick={() =>
                              handleHeaderItemClick(day.date, workingDays, helperForSelectDate, setIsDaySelected)
                           }
                           className="day"
                           data-date={day.actualDate}>
                           <Text label={translateWeekDays(day.date)} mb="none" />
                           <IntlDate date={day.date} dateFormat=" d" />
                        </SC.HeaderItem>
                     </SC.DayViewWrapper>
                  )
               })}
            </SC.WeekViewWrapperMobile>
         ) : (
            <SC.Header>
               <ArrowPrevious />

               <SC.WeekViewWrapperDesktop>
                  {daysRange?.map(day => {
                     const active = isSameDay(day, currentDate)
                     const disabled = isDayOff(day, workingDays)

                     return (
                        <SC.HeaderItem
                           key={`${day}`}
                           active={active}
                           disabled={disabled}
                           onClick={() =>
                              handleHeaderItemClick(day, workingDays, helperForSelectDate, setIsDaySelected)
                           }>
                           <Text label={translateWeekDays(day)} mb="none" />
                           <IntlDate date={day} dateFormat=" d" />
                        </SC.HeaderItem>
                     )
                  })}
               </SC.WeekViewWrapperDesktop>

               <ArrowNext />
            </SC.Header>
         )}
      </>
   )
}
