import './styles/index.less'
import '@plandok/core/styles/index.less'

import { ApolloProvider } from '@apollo/client'
import { IoC as CoreIoC, modalSlice, theme } from '@plandok/core'
import { getInitialLanguage, IntlProvider, IoC as IntlIoC } from '@plandok/i18n'
import { configureStore } from '@reduxjs/toolkit'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { AppRouter } from './AppRouter'
import GoogleAnalytics from './components/GoogleAnalytics'
import { graphqlClient } from './ghql'

// TODO configure IOC
IntlIoC.registerService({} as any)
CoreIoC.registerService({ useCountry: () => 'LT' } as any)

// if (process.env.NODE_ENV === 'production') {
//    if (process.env.REACT_APP_SENTRY_API_URL) {
//       Sentry.init({
//          dsn: process.env.REACT_APP_SENTRY_API_URL,
//          release: process.env.REACT_APP_SENTRY_RELEASE,
//          autoSessionTracking: true,
//          integrations: [new Integrations.BrowserTracing()],
//       })
//    }
//
//    if (process.env.REACT_APP_DATADOG_APP_ID && process.env.REACT_APP_DATADOG_CLIENT_TOKEN) {
//       // Datadog RUM Browser Monitoring init
//       datadogRum.init({
//          applicationId: process.env.REACT_APP_DATADOG_APP_ID,
//          clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
//          site: 'datadoghq.eu',
//          sampleRate: 100,
//          trackInteractions: true,
//       })
//    }
// }

const store = configureStore({
   reducer: {
      modalContext: modalSlice.reducer,
   },
})

const root = createRoot(document.getElementById('app') as HTMLElement)

root.render(
   <ApolloProvider client={graphqlClient}>
      <ReduxProvider store={store}>
         <ThemeProvider theme={theme}>
            <HelmetProvider>
               <BrowserRouter>
                  <IntlProvider getInitialLanguage={getInitialLanguage}>
                     <ThemeProvider theme={theme}>
                        <GoogleAnalytics />
                        <AppRouter />
                     </ThemeProvider>
                  </IntlProvider>
               </BrowserRouter>
            </HelmetProvider>
         </ThemeProvider>
      </ReduxProvider>
   </ApolloProvider>,
)
