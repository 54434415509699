import styled from 'styled-components'

export const SpinnerContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   background: ${({ theme }) => theme.background};
   width: ${({ theme }) => theme.width};
   height: ${({ theme }) => theme.height};
`
